<template>
	<DialogBase
		:dialogWidth="700"
		:dialogModel="dialogModel"
		@closeDialog="val => closeDialog(val)"
		scrollable
	>
		<template v-slot:title>
			{{ selectedModel ? `Update` : `Create` }} Identity
		</template>

		<v-form
			class="create-update-identity-form"
			id="form"
			@submit.prevent
			v-model="isValidForm"
			ref="from"
		>
			<FormMaker :schema="form_structure" :model="form_data"></FormMaker>
		</v-form>

		<template v-slot:footer>
			<v-btn color="accent" outlined block @click="submit()">
				{{ selectedModel ? `Update` : `Create` }}
			</v-btn>
		</template>
	</DialogBase>
</template>

<script>
	import { required, minLen } from "@/plugins/validationRules.js";
	import FormMaker from "@/components/FormMaker";
	import DialogBase from "@/components/dialogs/Base";
	export default {
		components: { DialogBase, FormMaker },
		props: {
			dialogModel: { required: true, type: Boolean },
			selectedModel: { required: true, default: null }
		},
		data() {
			return {
				isValidForm: false,
				form_data: {
					name: null,
					description: null,
					is_active: 1
				}
			};
		},
		mounted() {
			if (this.selectedModel) {
				this.form_data = Object.assign({}, this.selectedModel);
			} else {
			}
		},
		watch: {
			selectedModel(newVal, oldVal) {
				if (newVal) this.form_data = Object.assign({}, this.selectedModel);
			}
		},
		computed: {
			form_structure() {
				let form = {
					name: {
						type: "text",
						name: "name",
						label: `Name`,
						...this.$helpers.formFieldsBasicLayout,
						rules: [required()]
					},
					description: {
						type: "text",
						name: "description",
						label: `Description`,
						...this.$helpers.formFieldsBasicLayout,
						rules: [required()]
					},
					is_active: {
						type: "switch",
						name: "is_active",
						label: `Active : ${this.form_data.is_active ? "Yes" : "No"}`,
						...this.$helpers.formFieldsBasicLayout,
						hideDetails: false,
						inset: true,
						color: "primary",
						dense: true,
						truthyValue: 1,
						falsyValue: 0,
						rules: []
					}
				};
				return form;
			}
		},
		methods: {
			closeDialog(val = false) {
				this.$emit("closeDialog", val);
			},
			refresh(val = false) {
				this.$emit("refresh");
			},
			async submit() {
				this.$refs.from.validate();
				if (!this.isValidForm) return;

				let data = {
					...this.form_data
				};

				if (this.selectedModel)
					return await this.post(
						`${this.baseUrl}/agent-portal/create-update-identity`,
						data,
						true
					).then(data => {
						if (data == "undefined") return;
						this.showMessageFromResponseDta(data);

						if (data.code == 200) {
							this.refresh();
							this.closeDialog();
						}
					});

				return await this.post(
					`${this.baseUrl}/agent-portal/create-update-identity`,
					data,
					true
				).then(data => {
					if (data == "undefined") return;
					this.showMessageFromResponseDta(data);

					if (data.code == 200) {
						this.refresh();
						this.closeDialog();
					}
				});
			}
		}
	};
</script>

<style lang="scss">
	.create-update-identity-form {
		.type-switch {
			.input-custom-title {
				display: none;
			}
		}
	}
</style>
